import { request } from '@request'

export default {
  fetchData: (data = {}) =>
    request({
      url: '/abnormallyClaim/pageList',
      method: 'post',
      data,
    }),
  handleConfirm: (data = {}) =>
    request({
      url: '/abnormallyClaim/confirm',
      method: 'post',
      data,
    }),
  handleReject: (data = {}) =>
    request({
      url: '/abnormallyClaim/rebut',
      method: 'post',
      data,
    }),
}
